#root,
html,
body,
.App {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #f9f6e7;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}
