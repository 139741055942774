.course-schedule {
  display: flex;
  width: 100%;
  background-color: #f9f6e7;
  color: #00567e;
}

.schedule-header {
  color: #e73c3e;
  padding: 40px 0;
  font-size: 50px;
}

.up-arrow {
  position: fixed;
  cursor: pointer;
  bottom: 40px;
  right: 40px;
  svg {
    height: 40px;
    width: 40px;
    border-radius: 50px;
    background-color: $blue;
    color: $body-bg;
  }
}

.top-content-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 50px;

  .top-content-section {
    width: 49%;
    padding: 30px;
    border-radius: 10px;
    background-color: rgb(238, 237, 234);
    .top-content-day {
      color: $secondary;
      cursor: pointer;
    }
  }
}

.class-header {
  font-weight: bolder;
}

.notification-item {
  color: $primary;
  text-decoration-color: $primary;
}

.today-date {
  margin-bottom: 50px;
}
.main-header-course-day {
  width: 100%;
}
.schedule-header,
.schedule-table {
  width: 90%;
}

.schedule-table {
  padding-bottom: 100px;
}

.sidebar {
  width: 18%;
  padding: 10px 30px;
  border-right: 1px solid lightgray;
}

.sidebar-courseweeks {
  padding: 30px 0;
  position: sticky;
  top: 40px;
  flex-wrap: nowrap;
  overflow-y: auto;
  height: 100vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.sidebar-modules {
  .module-section {
    padding: 10px 0 20px;
  }

  h4 {
    padding: 50px 0 20px;
  }
  .nav-link {
    padding: 8px 0;
  }
}

.sidebar-courseweeks::-webkit-scrollbar {
  display: none;
}

.nav-link {
  color: #00567e;
}

.sidebar-subheading {
  font-weight: 400;
  text-decoration: underline;
}

.current-wk-indicator {
  font-size: 12px;
  padding-left: 10px;
  color: $primary;
  svg {
    font-size: 10px;
  }
}

.content {
  width: 82%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.schedule-list {
  width: 60%;
  h1 {
    border-top: 1px solid lightgrey;
    padding: 100px 0 50px;
    text-align: center;
    color: $secondary;
  }
}

.day-header {
  padding: 20px 0;
}

tr,
td,
th {
  color: #00567e;
  text-decoration: none;
}

a,
a:hover {
  color: #00567e;
}

li {
  list-style: none;
  margin: 0;
}

ul {
  padding: 0;
}

.day-section {
  padding-bottom: 10px;
}

.day-class {
  padding: 30px 0;
  border-bottom: 1px solid #af231c;
}
.table-date {
  h6 {
    text-decoration: underline;
    cursor: pointer;
  }
}
.table {
  margin-bottom: 0;
}
.table-heading {
  padding-bottom: 10px;
}

.nav.nav-tabs .nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-right: 5px;
  width: 100px;
  height: 60px;
  background-color: #af231c;
  color: #f9f6e7;
  font-size: 100%;
  a {
    text-align: center;
    color: $body-bg;
  }
}

.nav.nav-tabs .nav-link.active {
  background-color: $primary;
  border: none;
}

.mb-3 {
  width: 80%;
  border-bottom: 1px solid #af231c;
}

.main-header-div {
  margin-bottom: 70px;
  .main-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.navbar-dark .navbar-nav .nav-link {
  padding: 0 30px;
}
.navbar {
  width: 100%;
  background-color: $secondary !important;
  a {
    color: $body-bg;
    font-size: 16px;
    font-weight: 600;
    padding: 0 30px;
  }
  a:hover {
    cursor: pointer;
  }

  img {
    width: 150px;
  }
  .navbar-brand {
    margin-right: 50px;
  }
}
.nav.nav-pills .nav-item {
  font-size: 14px;
}
.nav.nav-pills .nav-link {
  margin: 2px 0;
}

.nav.nav-pills .nav-link.active {
  background-color: #00567e;
  color: #f9f6e7;
}

.nav.nav-pills .nav-link:hover {
  background-color: lightgray;
  color: $blue;
}

.table-date {
  width: 130px;
}
.no-class-date {
  font-size: 14px;
  font-weight: 300;
  padding-left: 50px;
  color: black;
}
.tab-content {
  border: 2px solid $primary;
  border-top-width: 5px;
}

.schedule-accordion-container {
  width: 80%;
  padding-bottom: 100px;
  .current-week-accordion {
    width: 100%;
    .accordion-item {
      .schedule-table {
        width: 100%;
        height: 100%;
        padding-bottom: 0;
      }
    }
    .accordion-body {
      background-color: $body-bg;
    }
    .accordion-button.collapsed {
      padding: 20px 30px 10px;
    }
    .accordion-button:not(.collapsed) {
      background-color: $secondary;
      color: white;
      padding: 20px 30px 10px;
    }
    .accordion-button:not(.collapsed):after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    }
  }
}
