.date-picker {
  height: 100%;
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: $blue;
}

.input-form-container {
  padding: 30px 30px 5px;
  border-bottom: 1px solid $blue;
}

.input-form {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 25px;
  .input-fields {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .input {
    width: 30%;
  }
}

.submit-button-container {
  margin: 17px 0;
  display: flex;
  justify-content: space-around;
  .btn {
    width: 20%;
    color: $body-bg;
  }
}

.data-shift {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 40px;
  border-left: 1px solid lightgray;
}

.data-container {
  width: 100%;
}
